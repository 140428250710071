/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import classNames from 'classnames';

import { Header, Footer, Responsive } from 'client/components/common';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';

import bg from 'assets/client/images/bg.jpg';
import bgMob from 'assets/client/images/main-bg-mobile.jpg';
import logo from 'assets/client/images/main-logo.svg';

import { Main } from '../Main';
import { FormPopup } from './FormPopup';
import { FirstYnetCourse } from '../FirstYnetCourse';
import { SecondYnetCourse } from '../SecondYnetCourse';
import { FIRST_YNET_COURSE_ROUTE, SECOND_YNET_COURSE_ROUTE, ROOT_ROUTE } from '../../../constants/routes';

import * as css from './Intro.scss';

type Props = {|
	...EnhancedProps,
	history: RouterHistory,
	className?: string,
	in: boolean,
|};

type State = { isFormOpen: boolean };
class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	state = { isFormOpen: false };

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}

		if (prevState.isFormOpen !== this.state.isFormOpen) {
			if (document.body) document.body.style.overflow = this.state.isFormOpen ? 'hidden' : 'auto';
		}
	}

	toggleForm = (isOpen: boolean) => () => {
		this.setState({
			isFormOpen: isOpen,
		});
	};
	render() {
		const { className, transitionState, history } = this.props;
		const { isFormOpen } = this.state;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div className={classNames(css.intro, css[transitionState], className, isFormOpen && css.formIsOpened)}>
				<Header isMobile={isMobile} isMain={history?.location.pathname === ROOT_ROUTE} />
				<div className={css.content}>
					<div className={css.contentBg} style={{ backgroundImage: `url(${isMobile ? bgMob : bg})` }} />
					{!isMobile && (
						<div className={css.logoContainer}>
							<img src={logo} alt="logo shavim" />
						</div>
					)}
					<div className={css.contentWrapper}>
						<div className={css.mainContent}>
							<Switch>
								<Route exact path={ROOT_ROUTE} render={() => <Main />} />
								<Route
									path={FIRST_YNET_COURSE_ROUTE}
									render={() => <FirstYnetCourse openForm={this.toggleForm(true)} />}
								/>
								<Route
									path={SECOND_YNET_COURSE_ROUTE}
									render={() => <SecondYnetCourse openForm={this.toggleForm(true)} />}
								/>
							</Switch>
						</div>
					</div>
				</div>
				<Footer />

				{isFormOpen && <FormPopup onClose={this.toggleForm(false)} />}
			</div>
		);
	}
}

export default withTransition(Intro);
