// @flow
import React from 'react';

import ContentForm from '../../../common/ContentForm';

import css from './FormPopup.module.scss';

type Props = {
	onClose: () => void,
};
export const FormPopup = ({ onClose }: Props) => {
	return (
		<div className={css.overlay}>
			<div className={css.wrap}>
				<ContentForm onClose={onClose} />
			</div>
		</div>
	);
};
