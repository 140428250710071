// @flow

import * as React from 'react';
import classNames from 'classnames';
import { dataLayerEvents } from 'common/utils/gtag';
import { IS_DEVEL } from 'common/constants';

// Logos from right to left:
import paisLogoSrc from 'assets/client/images/header/mifal-pais.png';
import ichilovLogo from 'assets/client/images/header/Ichilov.png';
import ciscoLogo from 'assets/client/images/header/Cisco.png';
import cobLogo from 'assets/client/images/header/Cob.png';
import ynetLogoSrc from 'assets/client/images/logo_ynet.svg';
import shavimLogoSrc from 'assets/client/images/shavim-logo.png';
import rashiLogo from 'assets/client/images/header/Rashi.png';
import bezeqLogo from 'assets/client/images/header/Bezeq.png';
// import paisMobLogo from 'assets/client/images/header/pais-m.png';

import shavimLogoMobSrc from 'assets/client/images/shavim-logo-mob.png';

import AccessibilityIcon from '../AccessibilityIcon';

import Socials from '../Socials';
import * as css from './Header.scss';

type Props = {
	className?: string,
	isMobile: boolean,
	isMain?: boolean,
};

const Header = ({ className, isMobile, isMain }: Props) => {
	const onLogoClick = e => {
		const { name } = e.currentTarget.dataset;
		if (!IS_DEVEL) {
			dataLayerEvents.onLogoClick(name);
		}
	};

	return (
		<div className={classNames(css.header, className)} id="header">
			<div className={css.headerWrapper}>
				{!isMain ? (
					<div className={css.rightSide}>
						<span className={css.paisLogo} data-name="Mifaal Hapais" onClick={onLogoClick}>
							<img src={paisLogoSrc} alt="pais logo" />
						</span>
						<span className={css.ichilovLogo} data-name="Ichilov" onClick={onLogoClick}>
							<img src={ichilovLogo} alt="Ichilov logo" />
						</span>
						<span className={css.shavimLogo} data-name="Shavim" onClick={onLogoClick}>
							<img src={isMobile ? shavimLogoMobSrc : shavimLogoSrc} alt="Shavim logo" />
						</span>
						<span className={css.ynetLogo} data-name="ynet" onClick={onLogoClick}>
							<img src={ynetLogoSrc} alt="ynet-logo" />
						</span>
					</div>
				) : (
					<div className={css.rightSide}>
						<span className={css.paisLogo} data-name="Mifaal Hapais" onClick={onLogoClick}>
							<img src={paisLogoSrc} alt="pais logo" />
						</span>
						<span className={css.ichilovLogo} data-name="Ichilov" onClick={onLogoClick}>
							<img src={ichilovLogo} alt="Ichilov logo" />
						</span>
						<span className={css.ciscoLogo} data-name="Cisco" onClick={onLogoClick}>
							<img src={ciscoLogo} alt="Cisco logo" />
						</span>
						<span className={css.cobLogo} data-name="Cob" onClick={onLogoClick}>
							<img src={cobLogo} alt="Cob logo" />
						</span>
						<span className={css.shavimLogo} data-name="Shavim" onClick={onLogoClick}>
							<img src={isMobile ? shavimLogoMobSrc : shavimLogoSrc} alt="Shavim logo" />
						</span>
						<span className={css.ynetLogo} data-name="ynet" onClick={onLogoClick}>
							<img src={ynetLogoSrc} alt="ynet-logo" />
						</span>
						<span className={css.rashiLogo} data-name="Rashi" onClick={onLogoClick}>
							<img src={rashiLogo} alt="rashi logo" />
						</span>
						<span className={css.bezeqLogo} data-name="Bezeq" onClick={onLogoClick}>
							<img src={bezeqLogo} alt="bezeq logo" />
						</span>
					</div>
				)}
				<div className={css.leftSide}>
					<div className={css.sociallsWrapper}>
						<Socials minified={isMobile} />
						<AccessibilityIcon />
					</div>
				</div>
			</div>
		</div>
	);
};

Header.defaultProps = {
	className: '',
	isMain: false,
};

export { Header };
