/* eslint-disable max-len */
// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet-async';
// import 'antd/dist/antd.less';
import { detectIE } from 'common/utils/detect-ie';
import { ROOT_ROUTE } from 'client/constants';
import Intro from 'client/components/pages/Intro';

import faviconImg from 'assets/favicon.png';
import * as css from './App.scss';

type Props = {|
	history: RouterHistory,
	location: RouterLocation,
	isBusy?: boolean,
|};

class App extends React.PureComponent<Props> {
	static defaultProps = {
		isBusy: true,
	};

	async componentDidMount() {
		const isIE = detectIE();

		// tracker.init('UA-142761482-39');
		// this.props.checkAuthState();

		if (!this.props.isBusy) {
			this.removeRootPreloader();
		}

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');
		}
	}

	async componentDidUpdate(prevProps: Props) {
		if (!this.props.isBusy && prevProps.isBusy) {
			this.removeRootPreloader();
		}
	}

	renderHelmet = () => {
		const title = 'שווים בתעסוקה – קידום אנשים עם מוגבלות בשוק התעסוקה';
		// eslint-disable-next-line max-len
		const description = `"שווים בתעסוקה" – פרויקט משותף למפעל הפיס וקבוצת ידיעות אחרונות, מזמין אתכם להירשם לקורסים במגוון נושאים, כגון קורס עיתונות וקורס ניהול רשתות חברתיות וסושיאל`;
		const ogTitle = 'שווים בתעסוקה: ההרשמה לקורס עיתונות וקורס ניהול רשתות חברתיות יוצאת לדרך';
		const ogDescription = `פרויקט "שווים בתעסוקה" – המשותף לקבוצת ידיעות אחרונות - ynet, מפעל הפיס ועמותת "שווים" – מבקש להגדיל את שיעור המועסקים בקרב אנשים עם מוגבלויות. כיום שיעורם הוא כמחצית בלבד משיעור המועסקים שאין להם מוגבלויות.`;
		// Fix for whatsapp share on IOS
		//! Attention: do not import og images as variable, write path here:
		const image = 'https://shavimbataasuka.ynet.co.il/assets/client/share-2024.png';

		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: ogTitle },
					{ property: 'og:description', content: ogDescription },
					{ property: 'og:image', content: image },
					{ property: 'og:url,', content: 'https://shavimbataasuka.ynet.co.il' },
					{ property: 'og:type', content: 'website' },
				]}
			/>
		);
	};

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 2500);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	renderRoute = (Component: Function) => {
		return ({ match, ...props }) => {
			return <Component {...props} in={match !== null} />;
		};
	};

	render() {
		return (
			<div id="app" className={css.app}>
				{this.renderHelmet()}
				<div className={css.content}>
					<Route path={ROOT_ROUTE}>{props => <Intro {...props} in={!!props.match} />}</Route>
				</div>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	isBusy: false,
});

const mapDispatch = {};

export default withRouter(connect(mapState, mapDispatch)(App));
