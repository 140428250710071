// @flow
import * as React from 'react';
import { Field } from 'redux-form';

import Input from './Select';

type Props = {
	name: string,
	inputRef?: { current: ?HTMLInputElement } | null,
	className?: string,
	placeholder?: string,
	icon?: React.Node,
	options: Array<SelectOption>,
	mode?: 'single' | 'multiply',
	eventListeners?: { [id: string]: Function },
};

export default class Select extends React.PureComponent<Props> {
	static defaultProps = {
		inputRef: null,
		className: '',
		placeholder: '',
		icon: null,
		mode: 'single',
		eventListeners: {},
	};

	render() {
		const { name, className, placeholder, options, icon, mode, inputRef, eventListeners } = this.props;
		const InputComponent = props => (
			<Input
				{...props}
				placeholder={placeholder}
				className={className}
				inputRef={inputRef}
				icon={icon}
				options={options}
				mode={mode}
				eventListeners={eventListeners}
			/>
		);

		return <Field {...this.props} name={name} component={InputComponent} />;
	}
}
