/* eslint-disable react/no-unescaped-entities */
// @flow
import * as React from 'react';
import classNames from 'classnames';

import close from 'assets/client/popups/close.svg';

import * as css from './SendedForm.scss';

type Props = { className?: string, closePopup: () => void };

const SendedFormPopup = ({ className, closePopup }: Props) => {
	return (
		<div className={classNames(css.sendedForm, className)}>
			<div className={classNames(css.sendedFormWrapper)}>
				<div className={css.close} onClick={closePopup}>
					<img src={close} alt="" />
				</div>
				<span className={css.title}>תודה, פרטיך נקלטו במערכת</span>
			</div>
		</div>
	);
};

SendedFormPopup.defaultProps = { className: '' };

export default SendedFormPopup;
