// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import * as css from './Radio.scss';

type Props = {
	input: {
		name: string,
		onChange: Function,
		value?: string,
	},
	meta: {
		touched?: boolean,
		error?: string,
		submitFailed?: boolean,
	},
	className?: string,
	label?: ?string,
	changeForm?: Function,
	changebleAnotherField?: string,
};

export default class Radio extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		label: null,
		changeForm: () => {},
		changebleAnotherField: '',
	};

	render() {
		const { className, input, label, changebleAnotherField, changeForm, ...rest } = this.props;
		const checked = _.get(input, 'checked', false);
		const isErrorShown = rest.meta.error && rest.meta.submitFailed && rest.meta.touched;
		const isActive = checked;

		const onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
			if (changebleAnotherField) {
				if (changeForm) {
					changeForm('userInfo', changebleAnotherField, '');
				}
			}
			_.invoke(this.props, 'input.onChange', e);
		};

		return (
			<label className={classNames(css.radio, isActive && css.active, isErrorShown && css.error, className)}>
				<div className={css.control} />
				{label && <div className={css.label}>{label}</div>}
				<input {...input} type="radio" onChange={onChange} />
			</label>
		);
	}
}
