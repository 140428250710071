/* eslint-disable react/no-array-index-key */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import * as css from './ExtensionsError.scss';

type Props = { className?: string, closePopup: Function, title: string };

class ExtensionErrorPopup extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
	};

	componentDidMount() {}

	clickOnCloseListener = () => {
		const { closePopup } = this.props;

		closePopup();
	};

	render() {
		const { className, title } = this.props;

		return (
			<div className={classNames(css.extensionsError, className)}>
				<div className={classNames(css.extensionsErrorWrapper)}>
					<div className={css.closeBtn} onClick={this.clickOnCloseListener}></div>
					<p>{title}</p>
				</div>
			</div>
		);
	}
}

export default ExtensionErrorPopup;
