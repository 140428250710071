// @flow

/* eslint-disable prefer-template, max-len */
import _ from 'lodash';

import { APP_CONFIG, CAN_USE_DOM, SHARE_CONSTANTS } from 'common/constants';
import { Responsive } from 'client/components/common/Responsive';

// eslint-disable-next-line import/prefer-default-export
export const share = (type: string, cb: Function = _.noop) => {
	if (!CAN_USE_DOM) {
		return;
	}

	// const utmHostname = Responsive.isMatching(Responsive.DESKTOP) ? 'ynet.co.il' : 'm.ynet.co.il';
	// const utms = `?utm_source=${utmHostname}&utm_medium=social&utm_campaign=${type}&utm_term=wellness_menta`;
	const domain = `${APP_CONFIG.domains.client.hostname}`;
	const titleProp = CAN_USE_DOM ? document.querySelector('meta[property="og:title"]') : null;
	const title: ?string = titleProp ? titleProp.getAttribute('content') : '';
	const descriptionProp = CAN_USE_DOM ? document.querySelector('meta[property="og:description"]') : null;
	const description: ?string = descriptionProp ? descriptionProp.getAttribute('content') : '';
	const imageProp = CAN_USE_DOM ? document.querySelector('meta[property="og:image"]') : null;
	const image: ?string = imageProp ? imageProp.getAttribute('content') : '';
	const encode = encodeURIComponent;
	const text = title && description ? `${title}\n${description}` : '';

	if (type === SHARE_CONSTANTS.FACEBOOK) {
		const fbURL = 'https://www.facebook.com/sharer.php?u=';

		window.open(`${fbURL}${domain}`, 'sharer', 'width=555,height=600');
	}

	if (type === SHARE_CONSTANTS.WHATSAPP) {
		if (title && description && image) {
			const sharetText = `${title}\n${description}\n${domain}`;
			const waShareLink = `https://wa.me/?text=${encode(`${sharetText}`)}`;

			if (Responsive.isMatching(Responsive.DESKTOP)) {
				window.open(waShareLink, '_blank');
			} else {
				window.location.href = waShareLink;
			}
		}
	}

	if (type === SHARE_CONSTANTS.TWITTER) {
		const twURL = 'https://twitter.com/intent/tweet?url=';
		const finalURL = `${twURL}${domain}&text=${encode(`${text}\n`)}`;

		const shareWindow = window.open(finalURL, 'ShareOnTwitter', 'width=555,height=600');
		shareWindow.opener = null;
	}

	cb();
};
