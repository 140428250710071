/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';
import { initialize, SubmissionError } from 'redux-form';

import { LEAD_CONSTANTS, CAN_USE_DOM, IS_DEVEL } from 'common/constants';
import { PLACEHOLDERS_TEXTS, COLLECTIONS, ERRORS_TEXTS } from 'client/constants';
import { Form, TextField, Radio, Checkbox, MediaField, Button } from 'client/components/common';
import { createItem } from 'client/actions';
import { dataLayerEvents } from 'common/utils/gtag';

import terms from 'assets/client/terms2024.docx';
import closeIcon from 'assets/client/images/x.svg';

import { Responsive } from '../Responsive';
import SendedFormPopup from '../Popups/SendedFormPopup';
import FilesSizeLimitPopup from '../Popups/FileSizeLimitPopup';
import AlreadyInSystemPopup from '../Popups/AlreadyInSystemPopup';
import ExtensionErrorPopup from '../Popups/ExtensionErrorPopup';

import * as css from './ContentForm.scss';

type FormData = {
	fullName?: string,
	age?: string,
	phoneNumber?: string,
	mobilityStatus?: string,
	address?: string,
	email?: string,
	resumeFile?: FileURL,
	motivationFile?: FileURL,
	disabilities?: string,
	special?: string,
	checkbox?: boolean,
	onRehabilitationNow?: string,
	rehabilitationPlace?: string, // no need if "onRehabilitationNow" is 'no' or empty
	whyYouNeedCourse?: string,
	route?: string,
};

type Props = {
	values: FormData,
	errors: FormData,
	onClose: () => void,
	createItem: typeof createItem,
};

type State = {
	isShowSenededFormPopup: boolean,
	isShowFilesSizeLimitPopup: boolean,
	isLoading: boolean,
	isShowAlreadyInSystemPopup: boolean,
	isShowExtensionsErrorPopup: boolean,
	extensionsErrorTitle: string,
};

const maxWordLength = (value: string, maxWords: number) => {
	if (value === undefined) return maxWords + 1 < 0;

	return value.toString().split(' ').length > maxWords + 1;
};

const FORM_CONFIG = {
	form: 'userInfo',
};

class ContentForm extends React.PureComponent<Props, State> {
	static defaultProps = {
		// className: '',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isShowSenededFormPopup: false,
			isShowFilesSizeLimitPopup: false,
			isLoading: false,
			isShowAlreadyInSystemPopup: false,
			isShowExtensionsErrorPopup: false,
			extensionsErrorTitle: '',
		};
	}

	componentDidMount() {}

	onFormSubmit = async (values: FormData) => {
		this.setState({
			isLoading: true,
		});

		const errorsObj = {};
		const phoneRegExp = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;
		const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!values.fullName) {
			errorsObj.fullName = ERRORS_TEXTS.fullName;
		} else if (values.fullName.split(' ').length < 2 || values.fullName.split(' ').some(name => name.length < 2)) {
			errorsObj.fullName = ERRORS_TEXTS.fullNameInvalid;
		}

		if (!values.age) {
			errorsObj.age = ERRORS_TEXTS.age;
		}

		if (!values.address) {
			errorsObj.address = ERRORS_TEXTS.address;
		} else if (values.address.length < 2) {
			errorsObj.address = ERRORS_TEXTS.addressNotValid;
		}

		if (!values.resumeFile) {
			errorsObj.resumeFile = ERRORS_TEXTS.resumeFile;
		}

		if (!values.phoneNumber) {
			errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumber;
		} else if (values.phoneNumber) {
			const validPhone = phoneRegExp.test(values.phoneNumber.toLocaleLowerCase());
			if (!validPhone) {
				errorsObj.phoneNumber = ERRORS_TEXTS.phoneNumberNotValid;
			}
		}

		if (!values.mobilityStatus) {
			errorsObj.mobilityStatus = ERRORS_TEXTS.mobilityStatus;
		}

		if (!values.email) {
			errorsObj.email = ERRORS_TEXTS.email;
		} else if (values.email) {
			const validEmail = emailRegExp.test(values.email.toLocaleLowerCase());
			if (!validEmail) {
				errorsObj.email = ERRORS_TEXTS.emailNotValid;
			}
		}

		if (!values.disabilities) {
			errorsObj.disabilities = ERRORS_TEXTS.disabilities;
		} else if (values.disabilities && maxWordLength(values.disabilities, 70)) {
			errorsObj.disabilities = ERRORS_TEXTS.disabilitiesMaxLength;
		}

		if (!values.special) {
			errorsObj.special = ERRORS_TEXTS.special;
		} else if (values.special && maxWordLength(values.special, 120)) {
			errorsObj.special = ERRORS_TEXTS.specialMaxLength;
		}

		if (!values.onRehabilitationNow) {
			errorsObj.onRehabilitationNow = ERRORS_TEXTS.onRehabilitationNow;
		}

		if (values.onRehabilitationNow === 'yes' && !values.rehabilitationPlace) {
			errorsObj.rehabilitationPlace = ERRORS_TEXTS.rehabilitationPlace;
		} else if (values.rehabilitationPlace && maxWordLength(values.rehabilitationPlace, 70)) {
			errorsObj.rehabilitationPlace = ERRORS_TEXTS.rehabilitationPlaceMaxLength;
		}

		if (!values.whyYouNeedCourse) {
			errorsObj.whyYouNeedCourse = ERRORS_TEXTS.whyYouNeedCourse;
		} else if (values.whyYouNeedCourse && maxWordLength(values.whyYouNeedCourse, 100)) {
			errorsObj.whyYouNeedCourse = ERRORS_TEXTS.whyYouNeedCourseMaxLength;
		}

		if (!values.checkbox) {
			errorsObj.checkbox = ERRORS_TEXTS.checkbox;
		}

		if (!_.isEmpty(errorsObj)) {
			this.setState({
				isLoading: false,
			});

			if (!IS_DEVEL) {
				dataLayerEvents.onBtnSubmitClick('Fail - Missing Field');
			}

			throw new SubmissionError(errorsObj);
		}

		if (_.isEmpty(errorsObj)) {
			const route = window.location.pathname;
			const leadResult = await this.props.createItem<any>({
				collection: COLLECTIONS.LEADS,
				data: { ...values, route },
			});

			if (leadResult.lead === LEAD_CONSTANTS.FOUNDED) {
				this.setState({
					isLoading: false,
					isShowAlreadyInSystemPopup: true,
				});
				if (!IS_DEVEL) {
					dataLayerEvents.onBtnSubmitClick();
				}
			}

			if (leadResult.lead === LEAD_CONSTANTS.CREATED) {
				this.setState({
					isShowSenededFormPopup: true,
					isLoading: false,
				});
				if (!IS_DEVEL) {
					dataLayerEvents.onBtnSubmitClick();
				}
			}
		}
	};

	closeErrorPopup = () => {
		this.setState({
			isLoading: false,
		});
	};

	closeFilesSizeLimitPopup = () => {
		this.setState({
			isShowFilesSizeLimitPopup: false,
		});
	};

	showFilesSizeLimitPopup = () => {
		this.setState({
			isShowFilesSizeLimitPopup: true,
		});
	};

	closeAlreadyPopup = () => {
		this.setState({
			isShowAlreadyInSystemPopup: false,
		});
	};

	closeSendedPopup = () => {
		this.setState({
			isShowSenededFormPopup: false,
		});
	};

	showExtensionsErrorPopup = (title: string) => {
		this.setState({
			isShowExtensionsErrorPopup: true,
			extensionsErrorTitle: title,
		});
	};

	closeExtensionsErrorPopup = () => {
		this.setState({
			isShowExtensionsErrorPopup: false,
		});

		setTimeout(() => {
			this.setState({
				extensionsErrorTitle: '',
			});
		}, 400);
	};

	render() {
		const { errors, onClose } = this.props;
		const {
			isShowSenededFormPopup,
			isShowFilesSizeLimitPopup,
			isShowAlreadyInSystemPopup,
			isShowExtensionsErrorPopup,
			extensionsErrorTitle,
		} = this.state;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		const errorsList = { ...errors };

		let macOs;

		if (CAN_USE_DOM) {
			macOs = /(Mac)/i.test(navigator.platform);
		}

		const wordCount = (value, limit) => {
			if (value === undefined) return 0;
			return value.trim().split(' ').length;
		};

		return (
			<div className={css.formWrapper}>
				<div className={css.close} onClick={onClose}>
					<img src={closeIcon} alt="" />
				</div>
				<div className={css.formTitle}>שאלון פרטים אישיים למועמד/ת</div>
				<Form className={css.form} config={FORM_CONFIG} onSubmit={this.onFormSubmit}>
					<div className={classNames(css.formRow, css.multipleInputs)}>
						<div className={css.fieldsWrapper}>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField
									name="fullName"
									placeholder={PLACEHOLDERS_TEXTS.fullName}
									pattern="^[\u0590-\u05FF /]+$"
								/>
								<p className={css.error}>{errorsList.fullName}</p>
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField name="age" placeholder={PLACEHOLDERS_TEXTS.age} pattern="^[0-9]+$" />
								<p className={css.error}>{errorsList.age}</p>
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField name="address" placeholder={PLACEHOLDERS_TEXTS.address} />
								<p className={css.error}>{errorsList.address}</p>
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField
									name="email"
									placeholder={PLACEHOLDERS_TEXTS.email}
									isEmailField
									pattern="^[a-zA-Z@. 0-9]+$"
								/>
								<p className={css.error}>{errorsList.email}</p>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow, css.multipleInputs, css.secondFormRow)}>
						<div className={css.fieldsWrapper}>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField
									name="phoneNumber"
									placeholder={PLACEHOLDERS_TEXTS.phone}
									pattern="^[0-9]+$"
								/>
								<p className={css.error}>{errorsList.phoneNumber}</p>
							</div>
							<div className={classNames(css.fieldWrapper, css.shortField)}>
								<TextField name="mobilityStatus" placeholder={PLACEHOLDERS_TEXTS.mobilityStatus} />
								<p className={css.error}>{errorsList.mobilityStatus}</p>
							</div>
							<div className={classNames(css.fieldWrapper, css.middleField)}>
								<MediaField
									name="resumeFile"
									placeholder={
										<div className={css.placeholder}>
											צרפו כאן קובץ קורות חיים
											<span>
												(<b>PDF, WORD</b> <i>עד</i> <b>10MB</b>)
											</span>
										</div>
									}
									sizeLimit={10 * 1024 * 1024}
									onShowSizeLimitPopup={this.showFilesSizeLimitPopup}
									extensions={[
										'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
										'application/msword',
										'application/pdf',
									]}
									extensionError={ERRORS_TEXTS.resumeFileFileExtensionError}
									onShowExtensionPopup={this.showExtensionsErrorPopup}
								/>
								<p className={css.error}>{errorsList.resumeFile}</p>
							</div>
						</div>
					</div>
					<div className={classNames(css.formRow)}>
						<div className={css.rowTitle}> ספרו לנו על עצמכם/ן:</div>
						<div className={css.fieldWrapper}>
							<TextField
								name="disabilities"
								placeholder="[עד 70 מילים]"
								isTextarea
								textareaType={1}
								maxWordsLength={70}
							/>
							<span
								style={{
									color: wordCount(this.props.values.disabilities) > 70 ? 'red' : '',
								}}
								className={css.wordCount}
							>
								70 /{' '}
								{wordCount(this.props.values.disabilities) > 70
									? 70
									: wordCount(this.props.values.disabilities)}
							</span>
							<p className={css.error}>{errorsList.disabilities}</p>
						</div>
					</div>
					<div className={css.formRow}>
						<div className={css.rowTitle}>ספרו לנו על ההתמודדות שלכם בעקבות המלחמה:</div>
						<div className={css.fieldWrapper}>
							<TextField
								name="special"
								placeholder="[עד 120 מילים]"
								isTextarea
								textareaType={1}
								maxWordsLength={120}
							/>
							<span
								style={{
									color: wordCount(this.props.values.special) > 120 ? 'red' : '',
								}}
								className={css.wordCount}
							>
								120 /{' '}
								{wordCount(this.props.values.special) > 120
									? 120
									: wordCount(this.props.values.special)}
							</span>
							<p className={css.error}>{errorsList.special}</p>
						</div>
					</div>
					<div className={css.formRow}>
						<div className={css.rowTitle}>האם אתם/ן בתהליך שיקומי כעת?</div>
						<div className={classNames(css.fieldWrapper, css.flex)}>
							<Radio name="onRehabilitationNow" label="כן" value="yes" />
							<Radio name="onRehabilitationNow" label="לא" checked value="no" />
							<p className={css.error}>{errorsList.onRehabilitationNow}</p>
						</div>
					</div>
					{this.props.values.onRehabilitationNow === 'yes' && (
						<div className={css.formRow}>
							<div className={css.rowTitle}>במידה וכן, היכן אתם/ן מטופלים/ןת?</div>
							<div className={css.fieldWrapper}>
								<TextField
									name="rehabilitationPlace"
									placeholder="[עד 70 מילים]"
									isTextarea
									textareaType={1}
									maxWordsLength={70}
								/>
								<span
									style={{
										color: wordCount(this.props.values.rehabilitationPlace) > 70 ? 'red' : '',
									}}
									className={css.wordCount}
								>
									70 /{' '}
									{wordCount(this.props.values.rehabilitationPlace) > 70
										? 70
										: wordCount(this.props.values.rehabilitationPlace)}
								</span>
								<p className={css.error}>{errorsList.rehabilitationPlace}</p>
							</div>
						</div>
					)}
					<div className={css.formRow}>
						<div className={css.rowTitle}>
							מדוע בחרתם/ן להירשם לקורס? ספרו לנו מה אתם שואפים להפיק ממנו:
						</div>
						<div className={css.fieldWrapper}>
							<TextField
								name="whyYouNeedCourse"
								placeholder="[עד 100 מילים]"
								isTextarea
								textareaType={1}
								maxWordsLength={100}
							/>
							<span
								style={{
									color: wordCount(this.props.values.whyYouNeedCourse) > 100 ? 'red' : '',
								}}
								className={css.wordCount}
							>
								100 /{' '}
								{wordCount(this.props.values.whyYouNeedCourse) > 100
									? 100
									: wordCount(this.props.values.whyYouNeedCourse)}
							</span>
							<p className={css.error}>{errorsList.whyYouNeedCourse}</p>
						</div>
					</div>
					<div className={css.formRow}>
						{isMobile && (
							<div className={css.rowTitle}>
								צרפו קובץ טקסט מקורי עד 150 מילה, וספרו בו על הסיבות והמוטיבציה שלכם ומדוע תרצו לעבוד
								בתחום התקשורת
							</div>
						)}
						<div className={classNames(css.fieldWrapper)}>
							<MediaField
								name="motivationFile"
								placeholder={isMobile ? '' : PLACEHOLDERS_TEXTS.motivationFile}
								sizeLimit={10 * 1024 * 1024}
								onShowSizeLimitPopup={this.showFilesSizeLimitPopup}
								extensions={[
									'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
									'application/msword',
									'application/pdf',
								]}
								extensionError={ERRORS_TEXTS.resumeFileFileExtensionError}
								onShowExtensionPopup={this.showExtensionsErrorPopup}
							/>
							<p className={css.error}>{errorsList.motivationFile}</p>
						</div>
					</div>

					<div className={css.formRow}>
						<div className={classNames(css.fieldWrapper)}>
							<Checkbox
								name="checkbox"
								label={
									<>
										הנני מסכים/ה{' '}
										<a href={terms} target="_blank" rel="noreferrer">
											לתקנון
										</a>
										,{' '}
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
											target="_blank"
											rel="noreferrer"
										>
											תנאי שימוש
										</a>
										,{' '}
										<a
											href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html"
											target="_blank"
											rel="noreferrer"
										>
											מדיניות פרטיות
										</a>{' '}
										ולקבלת פניות שיווקיות כולל טלפוניות מ-Ynet. הנ"ל איננה הסכמה לעריכת עסקה.
									</>
								}
							/>
							<p className={css.error}>{errorsList.checkbox}</p>
						</div>
					</div>
					<div className={classNames(css.formRow, css.withButton)}>
						{this.state.isLoading && <div className={css.preloader}></div>}
						<Button
							className={classNames(css.submitBtn, !macOs && css.forWindows)}
							label={this.state.isLoading ? '' : 'שליחת טופס'}
							type="submit"
						/>
					</div>
				</Form>

				<SendedFormPopup className={isShowSenededFormPopup ? 'show' : ''} closePopup={this.closeSendedPopup} />
				<AlreadyInSystemPopup
					className={isShowAlreadyInSystemPopup ? 'show' : ''}
					closePopup={this.closeAlreadyPopup}
				/>
				<FilesSizeLimitPopup
					className={isShowFilesSizeLimitPopup ? 'show' : ''}
					closePopup={this.closeFilesSizeLimitPopup}
				/>
				<ExtensionErrorPopup
					className={isShowExtensionsErrorPopup ? 'show' : ''}
					closePopup={this.closeExtensionsErrorPopup}
					title={extensionsErrorTitle}
				/>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => {
	const values = 'form.userInfo.values';
	const errors = 'form.userInfo.submitErrors';

	return {
		values: _.get(state, `${values}`, {}),
		errors: _.get(state, `${errors}`, {}),
	};
};

const mapDispatch = {
	initializeForm: initialize,
	createItem,
};

export default connect(mapState, mapDispatch)(ContentForm);
