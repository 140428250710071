// @flow
/* eslint-disable max-len */

import React from 'react';
import cn from 'classnames';

import ynetLogo from 'assets/client/courses_images/ynet-course-logo.png';

import css from './FirstYnetCourse.module.scss';

type Props = {
	openForm: () => void,
};

export const FirstYnetCourse = ({ openForm }: Props) => {
	return (
		<main className={css.firstYnetCourse}>
			<div className={css.title}>
				<span>{TEXTS.title}</span>
			</div>
			<div className={css.logo}>
				<img src={ynetLogo} alt="" />
			</div>
			<div className={cn(css.firstOptions, css.optionsBlock)}>
				<div className={css.title}>
					<div>
						<span>{TEXTS.options.firstList.title}</span>
					</div>
				</div>
				<ul className={css.options}>
					<li className={css.bold}>{TEXTS.options.firstList.first}</li>
					<li className={css.bold}>{TEXTS.options.firstList.second}</li>
					<li className={cn(css.bold, css.bottomGap)}>{TEXTS.options.firstList.third}</li>
					<li>{TEXTS.options.firstList.fourth}</li>
					<li>{TEXTS.options.firstList.fifth}</li>
					<li>{TEXTS.options.firstList.sixth}</li>
					<li>{TEXTS.options.firstList.seventh}</li>
					<li>{TEXTS.options.firstList.eighth}</li>
				</ul>
			</div>
			<div className={cn(css.secondOptions, css.optionsBlock)}>
				<div className={css.title}>
					<div>
						<span>{TEXTS.options.secondList.title}</span>
					</div>
				</div>
				<ul className={css.options}>
					<li>{TEXTS.options.secondList.first}</li>
					<li>{TEXTS.options.secondList.second}</li>
					<li dangerouslySetInnerHTML={{ __html: TEXTS.options.secondList.third }} />
				</ul>
			</div>
			<div className={cn(css.thirdOptions, css.optionsBlock)}>
				<div className={css.title}>
					<div>
						<span>{TEXTS.options.thirdList.title}</span>
					</div>
				</div>
				<ul className={css.options}>
					<li>{TEXTS.options.thirdList.first}</li>
					<li>{TEXTS.options.thirdList.second}</li>
				</ul>
			</div>
			<div className={css.bottomText}>
				<div>
					<span>{TEXTS.bottomText}</span>
				</div>
			</div>
			<button type="button" className={css.openFormBtn} onClick={openForm}>
				<span>{TEXTS.buttonText}</span>
			</button>
		</main>
	);
};

const TEXTS = {
	title: 'קורס יצירת התוכן של ידיעות אחרונות לנפגעי המלחמה - יוצא לדרך!',

	options: {
		firstList: {
			title: 'תנאי קבלה למועמדים:',
			first: `מועמדות ומועמדים מגיל 18 ומעלה.`,
			second: 'נפגעי מלחמת חרבות ברזל (פיזית ו/או נפשית) - חיילים ואזרחים.',
			third: 'מעוניינים להשתלב בתום הקורסים בשוק העבודה.',
			fourth: `
			זכאות לתעודת בגרות, או ניסיון תעסוקתי של שלוש שנים לפחות (גם ללא בגרות), או ניסיון תעסוקתי של שנה לפחות בתחום התקשורת (גם ללא בגרות).`,
			fifth: `יתרון לבעלי תואר אקדמי, בוגרי קורסים בתחום התקשורת, יכולת כתיבה וניסוח גבוהה, בקיאות בענייני אקטואליה ובחדשות.`,
			sixth: `תהליך הקבלה כרוך במשלוח קורות חיים, ובמשלוח קטע טקסט בן כ-150 מילה.`,
			seventh: `בהמשך יידרשו המועמדים לבצע מטלת מיון קטנה (בכתב) ולעבור ראיון אישי (בזום).`,
			eighth: 'חובה לציין מראש אם נדרשות התאמות נגישות לראיון.',
		},

		secondList: {
			title: 'אודות הקורס:',
			first: `
			הקורס יאפשר למשתתפיו היכרות עם מגוון רחב של תחומי עשייה בעולמות יצירת התוכן, שישמשו את המשתתפים למטרות שונות – מהשמה ועד למיתוג עצמי ועסקי וביטוי עצמי, הסברה והשפעה חברתית. הקורס ישלב סדנאות מעשיות בכתיבה, עריכה עיתונאית, יצירת סרטוני סושיאל ושימוש בשפת הרשתות – מיומנויות בעלות חשיבות רבה בכל תפקיד ובכל גוף תקשורת.`,
			second: `הקורס מקיף 16 מפגשים שבועיים רצופים (פרט לימי חגים).`,
			third: `השיעורים יתקיימו ב<span>מרכז השיקומי של בית החולים איכילוב ושניים מתוכם בבית ידיעות אחרונות.</span>`,
		},

		thirdList: {
			title: 'זמנים:',
			first: 'ההרשמה לקורס תהיה פתוחה עד ה-2.6.24.',
			second: `המועד הצפוי לפתיחת הקורס הוא יום שני, 17.6.24.`,
		},
	},

	bottomText: 'מהרו להירשם. בהצלחה!',
	buttonText: 'להרשמה',
};
