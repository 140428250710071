// @flow
/* eslint-disable max-len */

import React from 'react';
import cn from 'classnames';

import ynetLogo from 'assets/client/courses_images/ynet-course-logo.png';

import css from './SecondYnetCourse.module.scss';

type Props = {
	openForm: () => void,
};

export const SecondYnetCourse = ({ openForm }: Props) => {
	return (
		<main className={css.firstYnetCourse}>
			<div className={css.title}>
				<span>{TEXTS.title}</span>
			</div>
			<div className={css.logo}>
				<img src={ynetLogo} alt="" />
			</div>
			<div className={cn(css.firstOptions, css.optionsBlock)}>
				<div className={css.title}>
					<div>
						<span>{TEXTS.options.firstList.title}</span>
					</div>
				</div>
				<ul className={css.options}>
					<li className={css.bold}>{TEXTS.options.firstList.first}</li>
					<li className={css.bold}>{TEXTS.options.firstList.second}</li>
					<li className={cn(css.bold, css.bottomGap)}>{TEXTS.options.firstList.third}</li>
					<li>{TEXTS.options.firstList.fourth}</li>
					<li>{TEXTS.options.firstList.fifth}</li>
					<li>{TEXTS.options.firstList.sixth}</li>
					<li>{TEXTS.options.firstList.seventh}</li>
					<li>{TEXTS.options.firstList.eighth}</li>
				</ul>
			</div>
			<div className={cn(css.secondOptions, css.optionsBlock)}>
				<div className={css.title}>
					<div>
						<span>{TEXTS.options.secondList.title}</span>
					</div>
				</div>
				<ul className={css.options}>
					<li>{TEXTS.options.secondList.first}</li>
					<li>{TEXTS.options.secondList.second}</li>
					<li>{TEXTS.options.secondList.third}</li>
				</ul>
			</div>
			<div className={cn(css.thirdOptions, css.optionsBlock)}>
				<div className={css.title}>
					<div>
						<span>{TEXTS.options.thirdList.title}</span>
					</div>
				</div>
				<ul className={css.options}>
					<li>{TEXTS.options.thirdList.first}</li>
					<li>{TEXTS.options.thirdList.second}</li>
				</ul>
			</div>
			<div className={css.bottomText}>
				<div>
					<span>{TEXTS.bottomText}</span>
				</div>
			</div>
			<button type="button" className={css.openFormBtn} onClick={openForm}>
				<span>{TEXTS.buttonText}</span>
			</button>
		</main>
	);
};

const TEXTS = {
	title: 'המחזור הראשון בקורס סושיאל של',

	options: {
		firstList: {
			title: 'תנאי קבלה למועמדים:',
			first: `מועמדות ומועמדים בגיל 18 עד 50`,
			second: `מוכרות ומוכרים בביטוח הלאומי או במשרד הביטחון עם אחוזי נכות של 40% ומעלה`,
			third: `מעוניינים להשתלב בתום הקורסים בשוק העבודה, בהיקף של חצי משרה ומעלה`,
			fourth: `זכאות לתעודת בגרות, או ניסיון תעסוקתי של שלוש שנים לפחות (גם ללא בגרות), או ניסיון תעסוקתי של שנה לפחות בתחום התקשורת (גם ללא בגרות).`,
			fifth: `יתרון לבעלי תואר אקדמי, בוגרי קורסים בתחום התקשורת, יכולת כתיבה וניסוח גבוהה, בקיאות בסיסית בפלטפורמות של רשתות חברתיות ויכולת יצירת תוכן בסיסית (צילום תמונה, צילום סרטון וכן הלאה).`,
			sixth: `תהליך הקבלה כרוך במשלוח קורות חיים, ובמשלוח קטע טקסט בן כ-150 מילה, המסביר מדוע המועמד/ת מעוניינים לעבוד בתחום המדיה החברתית ויצירת התוכן.`,
			seventh: `בהמשך יידרשו המועמדים לבצע מטלת מיון קטנה (בכתב) ולעבור ראיון אישי (בזום).`,
			eighth: `חובה לציין מראש אם נדרשות התאמות נגישות לראיון.`,
		},

		secondList: {
			title: 'אודות הקורס:',
			first: `הקורס יאפשר למשתתפיו היכרות עם כל הפלטפורמות הרלוונטיות של רשתות חברתיות, לרבות מיומנות יצירת תוכן, ניסוח, כתיבה, צילום, עריכה ויצירת אסטרטגיית תוכן למותגים מסוגים שונים.`,
			second: `הקורס מקיף 10 מפגשים שבועיים רצופים (פרט לימי חגים).`,
			third: `השיעורים יתקיימו מדי יום רביעי אחר הצהריים בבית ידיעות אחרונות בראשון לציון.`,
		},

		thirdList: {
			title: 'זמנים:',
			first: 'ההרשמה לקורס תהיה פתוחה עד 8 לאוקטובר 2023.',
			second: `המועד הצפוי לפתיחת המחזור הבא של הקורס הוא יום רביעי, 25 באוקטובר 2023`,
		},
	},

	bottomText: 'מהרו להירשם. בהצלחה!',
	buttonText: 'להרשמה',
};
