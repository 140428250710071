/* eslint-disable react/no-unused-prop-types */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import { Select } from 'antd';
import _ from 'lodash';

import * as css from './Select.scss';

type Props = {
	input: {|
		name: string,
		onBlur: Function,
		onChange: Function,
		onDragStart: Function,
		onDrop: Function,
		onFocus: Function,
		value?: Array<string> | string,
	|},
	meta: {|
		active?: boolean,
		asyncValidating?: boolean,
		autofilled?: boolean,
		dirty?: boolean,
		dispatch?: Function,
		error?: string,
		form?: string,
		initial?: string,
		invalid?: boolean,
		pristine?: boolean,
		submitFailed?: boolean,
		submitting?: boolean,
		touched?: boolean,
		valid?: boolean,
		visited?: boolean,
		warning?: string,
	|},
	inputRef?: { current: ?HTMLInputElement } | null,
	className?: string,
	placeholder?: string,
	icon?: React.Node,
	options: Array<SelectOption>,
	mode?: 'single' | 'multiply',
	eventListeners: {|
		onChange?: Function,
		onFocus?: Function,
		onBlur?: Function,
		onKeyDown?: Function,
		onKeyUp?: Function,
	|},
};

export default class Input extends React.PureComponent<Props> {
	static defaultProps = {
		inputRef: null,
		className: '',
		placeholder: '',
		mode: 'single',
		icon: null,
	};

	renderOptions = () => {
		const { options } = this.props;

		return _.map<SelectOption, React.Element<any>>(options, (option: SelectOption) => (
			<Select.Option key={option.id} className={css.option}>
				{option.label}
			</Select.Option>
		));
	};

	onChange = (value: Array<string>) => {
		const { input } = this.props;

		input.onChange(value);
	};

	render() {
		const { className, placeholder, mode, input, icon } = this.props;

		return (
			<label className={classNames(css.selectField, icon && css.withIcon, className)}>
				<div className={css.icon}></div>
				<Select mode={mode} value={input.value || undefined} onChange={this.onChange} placeholder={placeholder}>
					{this.renderOptions()}
				</Select>
			</label>
		);
	}
}
