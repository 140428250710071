/* eslint-disable max-len */
import React from 'react';

import bottomLogo from 'assets/client/images/footer-logos.jpg';

import { CoursesList } from './CoursesList';

import css from './Main.module.scss';

export const Main = () => {
	return (
		<main className={css.main}>
			<div className={css.texts}>
				<div className={css.title}>
					<span>{TEXTS.title}</span>
				</div>
				<div className={css.firstSubtitle}>
					<span>{TEXTS.firstSubtitle}</span>
				</div>
				<div className={css.secondSubtitle}>
					<span>{TEXTS.secondSubtitle}</span>
				</div>
				<div className={css.underText}>
					<span>{TEXTS.underText}</span>
				</div>
			</div>
			<div className={css.courses}>
				<CoursesList />
			</div>
			<div className={css.emailText}>
				<span>{TEXTS.emailText}</span>{' '}
				<a href={`mailto:${TEXTS.email}`} className={css.email}>
					{TEXTS.email}
				</a>
			</div>
			<div className={css.warningWrap}>
				<img src={bottomLogo} alt="" />
				<div className={css.text}>
					<span>{TEXTS.redText}</span>
				</div>
			</div>
		</main>
	);
};

const TEXTS = {
	title: `שווים בתעסוקה – "חרבות ברזל": הכשרות מקצועיות לנפגעי המלחמה`,
	firstSubtitle: `פרויקט "שווים בתעסוקה" הינו מיזם לאומי-חברתי, שמוקדש השנה לנפגעי מלחמת "חרבות ברזל" פיזית ו/או נפשית, חיילים ואזרחים. הפרויקט מתקיים ביוזמת מפעל הפיס ובשיתוף המרכז הרפואי איכילוב, סיסקו ישראל, קבוצת "ידיעות אחרונות", אתר שווים וגופים נוספים אשר חברו יחד כדי להציע הכשרות ייחודיות.`,
	secondSubtitle: `אנחנו כאן כדי לעזור לכם להשתלב בחזרה בשוק העבודה, להרוויח ידע מקצועי, קשרים והזדמנויות חדשות.`,
	underText: `מעוניינים/ות להשתתף? הירשמו כאן למטה לאחת ההכשרות שלנו!`,
	emailText: 'חברות נוספות שרוצות לפתוח הכשרות ייעודיות במסגרת המיזם, יש לפנות לאבישג אלבז הרוש-',
	email: 'Avishageh@gmail.com',
	redText:
		'כללי הפעילות המפורטים בתקנון חלים על הרשמה לקורסים מבית ידיעות אחרונות בלבד. על הרשמה לקורסים של גורמים אחרים שמתפרסמים בעמוד זה, יחולו תנאי השתתפות והמדיניות של כל אחד מהגורמים הנ"ל, שישמור את פרטי הנרשמים וישתמש בהם באחריותו הבלעדית, מבלי שידיעות אחרונות אחראית לאלה',
};
