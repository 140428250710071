// @flow
export * from './auth';
export * from './settings';

export const GET_ITEMS = {
	START: 'GET_ITEMS.START',
	SUCCESS: 'GET_ITEMS.SUCCESS',
	FAIL: 'GET_ITEMS.FAIL',
};

export const CREATE_ITEM = {
	START: 'CREATE_ITEM.START',
	SUCCESS: 'CREATE_ITEM.SUCCESS',
	FAIL: 'CREATE_ITEM.FAIL',
};

export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const DELETE_MEDIA = 'DELETE_MEDIA';
export const SET_POP_UP = 'SET_POP_UP';
