// @flow
import * as React from 'react';
import classNames from 'classnames';

import close from 'assets/client/popups/close.svg';

import * as css from './AlreadyInSystem.scss';

type Props = { className?: string, closePopup: () => void };

const AlreadyInSystemPopup = ({ className, closePopup }: Props) => {
	return (
		<div className={classNames(css.alreadyInSystem, className)}>
			<div className={classNames(css.alreadyInSystemWrapper)}>
				<div className={css.close}>
					<img src={close} alt="" onClick={closePopup} />
				</div>
				<p>
					<span>פרטיך כבר במערכת</span>
				</p>
			</div>
		</div>
	);
};

AlreadyInSystemPopup.defaultProps = { className: '' };

export default AlreadyInSystemPopup;
