// @flow
import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import type { FieldArrayProps } from 'redux-form';
import plus from 'assets/client/images/plus.png';
import minus from 'assets/client/images/minus.png';
import { Responsive } from '../../Responsive';
import TextField from '../TextField';
import * as css from './ParentsFieldsArray.scss';

declare type Props = {
	...FieldArrayProps,
	// className?: string,
	addParent: Function,
	removeParent: Function,
	parentsLength: number,
	errors: any,
};

export default class ParentsFieldsArray extends React.PureComponent<Props> {
	create = () => {
		this.props.addParent();
		this.props.fields.push({});
	};

	delete = () => {
		this.props.removeParent();
		this.props.fields.remove(this.props.parentsLength - 2);
	};

	renderFields = () => {
		const { errors } = this.props;

		// @flow-disable-next-line
		return _.map(this.props.fields, (fieldInfo: any, index: number) => {
			const isParentError = _.get(errors, `parent${index + 2}Name`, '');
			return (
				<div className={classNames(css.fieldWrapper, isParentError && css.error)} key={`parentfield-${index}`}>
					<TextField
						name={`parent${index + 2}Name`}
						pattern="^[\u0590-\u05FF /]+$"
						placeholder={`שם הורה ${index + 2}`}
					/>
				</div>
			);
		});
	};

	render() {
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		const isMobileLong = isMobile && this.props.fields.length === 1;
		const { errors } = this.props;
		const isParent1Error = _.get(errors, 'parent1Name', '');
		return (
			<div className={css.parentsFields}>
				<div className={classNames(css.fieldWrapper, isParent1Error && css.error)}>
					<TextField name="parent1Name" pattern="^[\u0590-\u05FF /]+$" placeholder="שם הורה 1" />
				</div>

				{this.renderFields()}
				<div className={classNames(css.buttons, isMobileLong && css.long)}>
					{this.props.fields.length !== 2 && (
						<div className={css.addBtn} onClick={this.create}>
							<img src={plus} alt="add" />
						</div>
					)}
					{this.props.fields.length !== 0 && (
						<div className={css.deleteBtn} onClick={this.delete}>
							<img src={minus} alt="remove" />
						</div>
					)}
				</div>
			</div>
		);
	}
}
