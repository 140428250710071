// @flow
export const ROOT_ROUTE = '/007';
export const LOGIN_ROUTE = `${ROOT_ROUTE}/login`;

export const DEMO_ROUTE = `${ROOT_ROUTE}/demo`;
export const EDIT_DEMO_ITEM_ROUTE = `${ROOT_ROUTE}/demo/:id/edit`;
export const CREATE_DEMO_ITEM_ROUTE = `${ROOT_ROUTE}/demo/create`;
export const USERS_ROUTE = `${ROOT_ROUTE}/users`;
export const BACKUP_ROUTE = `${ROOT_ROUTE}/backup`;
export const SESSION_LOGS_ROUTE = `${ROOT_ROUTE}/logs`;
export const SETTINGS_ROUTE = `${ROOT_ROUTE}/settings`;
export const LEADS_ROUTE = `${ROOT_ROUTE}/leads`;
export const LEADS_HTML_ROUTE = `${ROOT_ROUTE}/leads_html`;
