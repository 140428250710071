// @flow
import * as React from 'react';
import { Field } from 'redux-form';

import Input from './Radio';

type Props = {
	name: string,
	label?: ?string,
	value?: string,
	className?: string,
	disabled?: boolean,
	changeAnotherField?: Function,
	changebleAnotherField?: string,
};

export default class Radio extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		value: '',
		label: null,
		disabled: false,
		changeAnotherField: () => {},
		changebleAnotherField: '',
	};

	render() {
		const { name, value, label, className, disabled, changeAnotherField, changebleAnotherField } = this.props;
		const InputComponent = props => (
			<Input {...props} input={{ ...props.input, disabled }} className={className} label={label} />
		);

		return (
			<Field
				name={name}
				value={value}
				type="radio"
				changeForm={changeAnotherField}
				disabled={disabled}
				changebleAnotherField={changebleAnotherField}
				component={InputComponent}
			/>
		);
	}
}
