/* eslint-disable react/no-array-index-key */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import * as css from './FileSizeLimit.scss';

type Props = { className?: string, closePopup: Function };

class FileSizeLimitPopup extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
	};

	componentDidMount() {}

	clickOnCloseListener = () => {
		const { closePopup } = this.props;

		closePopup();
	};

	render() {
		const { className } = this.props;

		return (
			<div className={classNames(css.fileSizeLimit, className)}>
				<div className={classNames(css.fileSizeLimitWrapper)}>
					<div className={css.closeBtn} onClick={this.clickOnCloseListener}></div>
					<p>משקל הקבצים המקסימלי הינו 10 מגה בייט</p>
				</div>
			</div>
		);
	}
}

export default FileSizeLimitPopup;
