/* eslint-disable max-len */
// @flow

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	LEADS: 'leads',
};

export const PLACEHOLDERS_TEXTS = {
	fullName: 'שם מלא',
	age: 'גיל',
	address: 'מקום מגורים',
	phone: "מס' נייד",
	resumeFile: 'צרפו קובץ קורות חיים <span>[עד 120 מילים]</span>',
	email: 'כתובת מייל',
	mobilityStatus: 'ניידות',
	motivationFile: 'צרפו כאן תעודה/מסמך מטעם המדינה המכירים במוגבלות ו/או הפגיעה בימי המלחמה',
};

export const ERRORS_TEXTS = {
	fullName: 'יש למלא שם מלא',
	fullNameInvalid: 'יש למלא שם מלא',
	age: 'יש למלא גיל',
	address: 'יש למלא מקום מגורים',
	addressNotValid: 'יש למלא כתובת תקינה',
	phoneNumber: 'יש למלא מספר טלפון',
	phoneNumberNotValid: 'יש למלא מספר טלפון תקין',
	mobilityStatus: 'יש למלא האם נייד או ניידת',
	email: 'יש למלא כתובת מייל',
	emailNotValid: 'יש למלא כתובת מייל תקינה',
	resumeFile: 'יש להעלות קובץ קורות חיים',
	resumeFileFileSizeError: 'קובץ גדול מדי',
	resumeFileFileExtensionError: 'סוג קובץ לא נכון',
	motivationFile: 'יש להעלות קובץ טקסט',
	motivationFileSizeError: 'קובץ גדול מדי',
	motivationFileExtensionError: 'סוג קובץ לא נכון',
	disabilities: 'יש לספר עם איזו מוגבלות אתם מתמודדים. עד 70 מילים',
	disabilitiesMaxLength: 'יש לכתוב עד 70 מילים בלבד',
	special: 'יש לספר על ההתמודדות שלכם בעקבות המלחמה. עד 120 מילים',
	specialMaxLength: 'יש לכתוב עד 120 מילים בלבד',
	onRehabilitationNow: 'יש לבחור את אחת התשובות',
	rehabilitationPlace: 'יש לציין היכן אתם מטופלים. עד 70 מילים',
	rehabilitationPlaceMaxLength: 'יש לכתוב עד 70 מילים בלבד',
	whyYouNeedCourse: 'יש לספר מדוע בחרתם להירשם לקורס. עד 100 מילים',
	whyYouNeedCourseMaxLength: 'יש לכתוב עד 100 מילים בלבד',
	route: 'יש לבחור מסלול',
	checkbox: 'יש לאשר את התקנון, תנאי שימוש ומדיניות פרטיות',
};

export const texts = {
	introTitle: 'אין על האוכל של סבתא שלי!',
};
