import { CAN_USE_DOM } from '../constants';

export const dataLayerEvents = {
	/**
	 * @param label {String}
	 */
	onShareBtnClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'shavimBataasuka2023',
			Action: 'Share',
			Label: label,
		}),
	onAcessabilityBtnClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'shavimBataasuka2023',
			Action: 'Accessibility Button',
			Label: '',
		}),
	onLogoClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'shavimBataasuka2023',
			Action: 'clickToWebsite',
			Label: label,
		}),
	onBtnSubmitClick: label =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'shavimBataasuka2023',
			Action: `Form sent - ${label}`,
			Label: 'shavimbataasuka.ynet.co.il',
		}),
};
